import styled from "styled-components";
import theme from "../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 11px;
  background-color: ${templateColors.backgroundWhite};
  width: 600px;
  height: 260px;
  margin-top: 1%;
  position: relative;
  padding: 1.5% 2% 1.5% 2%;
  @media (max-width: 1224px) {
    width: 98%;
    height: 275px;
    margin-top: 2%;
    align-self: center;
    padding: 3% 2% 1.5% 4%;
  }
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${templateColors.backgroundWhite};
  width: 45%;
  height: 200px;
  position: relative;
  @media (max-width: 1224px) {
    height: 165px;
    width: 45%;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${templateColors.backgroundWhite};
  width: 55%;
  height: 240px;
  position: relative;
  @media (max-width: 1224px) {
    width: 55%;
    height: 210px;
    padding-right: 1%;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 14px;
  @media (max-width: 1224px) {
    width: 100%;
    margin-top: 10px;
  }
`;

export const RowTwo = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-top: 10px;
  margin-bottom: 30px;
  @media (max-width: 1224px) {
    width: 90%;
    margin-top: 7px;
  }
`;

export const RowThree = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  width: 100%;
  @media (max-width: 1224px) {
    margin-top: 10px;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${templateColors.lightDark};
  height: 15%;
  width: 100%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const BottomContainerTwo = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${templateColors.lightDark};
  height: 20%;
  width: 100%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  align-items: center;
  justify-content: center;
  @media (max-width: 1224px) {
    height: auto;
  }
`;

export const Tile = styled.span`
  font-weight: 500;
  font-size: ${fontSizes.title};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.titleMobile};
  }
`;

export const SubTile = styled.span`
  font-weight: 500;
  font-size: ${fontSizes.description};
  color: ${templateColors.lightGrey};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

export const Title = styled.span`
  font-weight: 500;
  font-size: ${fontSizes.title};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.titleMobile};
  }
`;

export const Description = styled.span`
  font-weight: 400;
  font-size: ${fontSizes.description};
  color: ${templateColors.secondaryGrey};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

export const DescriptionTwo = styled.span`
  font-weight: 500;
  font-size: ${fontSizes.description};
  margin-top: 2px;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
    margin-top: 2px;
  }
`;

export const DescriptionThree = styled.span`
  font-weight: 500;
  line-height: 21px;
  font-style: italic;
  font-size: ${fontSizes.description};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

export const BottomTile = styled.span`
  font-weight: 500;
  color: ${templateColors.lightYellow};
  font-size: ${fontSizes.description};
  margin-right: 8px;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

export const BottomTileContainer = styled.div`
  width: 85%;
  @media (max-width: 1224px) {
    padding: 10px;
  }
`;

export const Image = styled.img`
  height: 15px;
  width: 15px;
  @media (max-width: 1224px) {
    height: 14px;
    width: 14px;
  }
`;
