import React, { useState } from "react";

import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import {
  CHECKOUT_DONE,
  CHECKOUT_DONE_DESCRIPTION,
  COMPLETE_SUBSCRIPTION,
  GO_TO_DASHBOARD,
  PLEASE_CHECK_YOUR_CARD,
  WE_COULDNT_PROCCESS_PAYMENT,
} from "../../../utils/strings";
import { DASHBOARD_MEMBERS } from "../../../utils/routes";
import { emptyBasket } from "../../../slices/appSlice";
import { setLoggedIn, setMemberLoggedIn } from "../../../slices/authSlice";
import Modal from "../../../components/Modal";
import StatusScreen from "../../../components/StatusScreen";
import theme from "../../../utils/theme";
import { injectScript } from "../../../utils/utils";
import { Button, Container, Form } from "./styles";

const PaymentForm = () => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [paymentFailure, setPaymentFailure] = useState<boolean>(false);
  const [onReadyPaymentForm, setOnReadyPaymentForm] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userExist } = useSelector((state: RootState) => state.appData);

  const disabled = isProcessing || !userExist;

  const handlePayBasicSubscription = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) return;
    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/checkout`,
      },
      redirect: "if_required",
    });

    if (error) {
      setShowStatusModal(true);
      setPaymentFailure(true);
    }

    if (paymentIntent && paymentIntent.status === "succeeded") {
      setShowStatusModal(true);
      setPaymentFailure(false);
      injectScript();
    }
    setIsProcessing(false);
  };

  const handleOpenStatusModal = () => {
    if (!paymentFailure) {
      setShowStatusModal(!showStatusModal);
      dispatch(emptyBasket());
      dispatch(setLoggedIn(true));
      dispatch(setMemberLoggedIn(true));
      navigate(DASHBOARD_MEMBERS);
    } else {
      setShowStatusModal(!showStatusModal);
    }
  };

  return (
    <Container>
      <Form id="payment-form" onSubmit={handlePayBasicSubscription}>
        {!onReadyPaymentForm && (
          <div style={{ color: "white", paddingBottom: "20px" }}>
            Loading... <CircularProgress size={25} />
          </div>
        )}
        <PaymentElement
          id="payment-element"
          onReady={() => setOnReadyPaymentForm(true)}
        />
        <Button type="submit" disabled={disabled}>
          {isProcessing ? (
            <CircularProgress size={25} />
          ) : (
            COMPLETE_SUBSCRIPTION
          )}
        </Button>
      </Form>
      <Modal
        showModal={showStatusModal}
        onClose={handleOpenStatusModal}
        content={
          <StatusScreen
            title={paymentFailure ? WE_COULDNT_PROCCESS_PAYMENT : CHECKOUT_DONE}
            description={
              paymentFailure
                ? PLEASE_CHECK_YOUR_CARD
                : CHECKOUT_DONE_DESCRIPTION
            }
            successButtonTile={GO_TO_DASHBOARD}
            failure={paymentFailure}
            onClose={handleOpenStatusModal}
          />
        }
        backgroundColor={theme.templateColors.lightDark}
        closeColor={theme.templateColors.lightYellow}
      />
    </Container>
  );
};

export default PaymentForm;
