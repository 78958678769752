import React, { useEffect, useState } from "react";

import moment from "moment";
import { useSelector } from "react-redux";

import { WELCOME } from "../../utils/strings";
import Modal from "../Modal";
import DashboardModal from "../DashboardModal";
import theme from "../../utils/theme";
import { useApi } from "../../hooks";
import {
  getAllBusinessCall,
  getBusinessProfileCall,
  getUserCall,
} from "../../api/apiOperations";
import {
  Container,
  RightContainer,
  Subtitle,
  Title,
  LeftContainer,
  Image,
} from "./styles";

interface DashboardHeaderProps {
  avatar: string;
}

const DashboardHeader = ({ avatar }: DashboardHeaderProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const today = new Date();

  const { userData, userMembershipStatus } = useSelector(
    (state: RootState) => state.appData || {}
  );

  const { memberLoggedIn } = useSelector(
    (state: RootState) => state.auth || {}
  );

  const { request: getAllBusinessCallReq } = useApi<any>({
    apiFunc: getAllBusinessCall,
  });

  const { businessId } =
    userMembershipStatus?.memberships?.active?.business || {};

  const handleOpenModal = (e: any) => {
    e.preventDefault();
    setShowModal(!showModal);
  };

  const { request: getUserCallReq } = useApi<any>({
    apiFunc: getUserCall,
  });

  const { request: getBusinessProfileCallReq } = useApi<any>({
    apiFunc: getBusinessProfileCall,
  });

  useEffect(() => {
    getUserCallReq();
    if (memberLoggedIn) getBusinessProfileCallReq(businessId);
  }, [businessId, memberLoggedIn]);

  useEffect(() => {
    getAllBusinessCallReq();
  }, []);

  return (
    <Container>
      <LeftContainer>
        <Subtitle> {moment(today).format("MMMM Do YYYY")}</Subtitle>
        <Title>
          {WELCOME} {userData?.firstName || "we are happy to see you :)"}
        </Title>
      </LeftContainer>
      <RightContainer onClick={handleOpenModal}>
        <Image src={avatar} alt="Logo" />
      </RightContainer>
      <Modal
        showModal={showModal}
        onClose={handleOpenModal}
        content={<DashboardModal avatar={avatar} />}
        width="500px"
        padding="10px 0px 0px 0px"
        backgroundColor={theme.templateColors.lightDark}
        closeColor={theme.templateColors.lightYellow}
      />
    </Container>
  );
};

export default DashboardHeader;
