import styled from "styled-components";
import theme from "../../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${templateColors.primaryBlack};
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: ${templateColors.primaryBlack};
  width: 100%;
  padding-left: 20%;

  @media (max-width: 1224px) {
    justify-content: flex-start;
    width: 89%;
    padding-left: 0%;
    margin-top: 5px;
  }
`;

export const Tile = styled.span`
  color: ${colors.white};
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  @media (max-width: 1224px) {
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
  }
`;

export const GymHopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${templateColors.lightDark};
  max-width: 85%;
  overflow-y: hidden;
  border-radius: 12px;
  margin-top: 6%;
  padding: 2%;

  @media (max-width: 1224px) {
    margin-top: 20%;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1224px) {
    width: 80%;
    text-align: center;
  }
`;

export const GymHop = styled.div`
  background-color: ${templateColors.lightDark};
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  position: relative;
  height: 600px;
  width: 100%;
  z-index: 20;
  margin-top: 3%;
  overflow-y: scroll;
  gap: 2px;
  &::-webkit-scrollbar {
    width: 0px; /* Remove the scrollbar width */
    height: 0px; /* Remove the scrollbar height */
  }

  @media (max-width: 1224px) {
    max-width: 90%;
    overflow-x: hidden;
    justify-content: center;
  }
`;
export const GymHopeTitle = styled.span`
  color: ${colors.white};
  font-weight: 700;
  font-size: ${fontSizes.mediumTitle};
  line-height: 24px;
  margin-top: 18px;
  margin-bottom: 15px;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.mediumTitleMobile};
    font-weight: 500;
    line-height: 15px;
    margin-bottom: 15px;
    margin-top: 18px;
  }
`;

export const GymHopeSubtitle = styled.span`
  font-weight: 500;
  font-size: ${fontSizes.subtitle};
  color: ${templateColors.lightYellow};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
    margin-top: 15px;
  }
`;

export const GymHopeDescription = styled.span`
  font-weight: 400;
  font-size: ${fontSizes.description};
  color: ${templateColors.secondaryGrey};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
    line-height: 15px;
    font-weight: 500;
  }
`;
