import React from "react";

import { HashLink as Link } from "react-router-hash-link";
import { ArrowRight } from "../../assets/images";
import {
  Container,
  LogoStyle,
  Title,
  LeftContainer,
  Description,
  RightContainer,
} from "./styles";

interface CardProps {
  id: string;
  title: string;
  description: string;
}
const Card = ({ id, title, description }: CardProps) => {
  return (
    <Link to="#gym-hop" style={{ textDecoration: "none" }}>
      <Container id={id}>
        <LeftContainer>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </LeftContainer>
        <RightContainer>
          <LogoStyle src={ArrowRight} alt="arrow" />
        </RightContainer>
      </Container>
    </Link>
  );
};

export default Card;
