export const ABOUT_US_TITLE = "About Us";
export const ABOUT_US = "About us";
export const ACTIVE_MEMBERSHIPS = "Active Memberships";
export const ASK_FOR_HELP = "Ask for help";
export const ABOUT_US_DESCRIPTION_1 =
  "JackedRabbit aims to provide unparalleled value and service for both our members and gym partners to create a sustainable impact on our fitness community. Join us on this transformative journey as we reshape the future of fitness and community experiences.";
export const ABOUT_US_DESCRIPTION =
  "We created JackedRabbit to provide irresistible value for fitness seekers and to start putting Fitness Partners first.";
export const ABOUT_US_DESCRIPTION_2 =
  "At JackedRabbit, we strive to be the destination platform for all fitness seekers. We provide members the opportunity to fuse fitness and lifestyle seamlessly. JackedRabbit is committed to building an environment, free of obstacles, where members are empowered and inspired to take control over their health.";
export const ABOUT_US_DESCRIPTION_3 =
  "We are creating a community for our members and by our members. We are on a journey of inclusivity, positivity, and innovation. JackedRabbit works with coaches, influencers, and fitness professionals to provide our members with resources, content, and events for a fully-immersive community experience. Let’s make a lasting impact on the world of fitness.";
export const ACCOUNT_STATUS = "Account status";
export const ARE_YOU_SURE_YOU_WANT_TO_CANCEL =
  "Are you sure you want to cancel your subscription?";
export const YOUR_ARE_ABOUT_TO_UPGRADE =
  "Your are about to upgrade your membership:";
export const BACK = "Back";
export const BECOME_A_JACKEDRABBIT = "Become a JackedRabbit!";
export const BECOME_A_PARTNER = "Become a partner";
export const BECOME_A_PARTNER_TITLE = "Our Partners";
export const BECOME_A_PARTNER_TITLE_TWO = "Become a";
export const BECOME_A_PARTNER_DESCRIPTION =
  "Are you a Gym owner or Manager looking to expand your reach and attract more members? Welcome to JackedRabbit.";
export const BECOME_A_PARTNER_DESCRIPTION_TWO =
  "Let’s work together to build a healthier, happier, community one workout at a time.";
export const BECOME_A_PARTNER_DESCRIPTION_THREE =
  "Increase visibility and exposure by listing your gym today. Our sales and marketing campaigns are tailored to bring new members to your gym every month. We’re here to help your gym attract new members and reach your revenue potential.";
export const BECOME_A_PARTNER_SUBTITLE =
  "Partners dedicated to your fitness goals and benefits that extend beyond your workout.";
export const CANCEL = "Cancel";
export const CANCEL_SUBSCRIPTION = "Cancel subscription";
export const CHECKOUT_DONE = "Checkout done successfully 🎉";
export const CHECKOUT_DONE_DESCRIPTION =
  "Your subscription was successfully done. Now you can start enjoying your new gym!";
export const CREATE_PROFILE_CARD = "Create Profile Card";
export const CREATE_YOUR = "Create your JackedRabbit profile or,";
export const CONFIRM = "Confirm";
export const CONGRATS = "Congratulations 🎉 ";
export const CONTACT = "Contact";
export const CONTACT_INFORMATION = "Contact Information *";
export const CONTACT_US_TITLE = "Contact Us";
export const CONTACT_US_SUBTITLE =
  "By submitting your gym to our platform, you unlock a world of opportunities for exposure, promotion, and growth. Join our vibrant community of fitness professionals and enthusiasts and showcase what makes your gym unique.";
export const CONTACT_US_QUESTION_TITLE =
  "Have questions, feedback, or need assistance? Don't hesitate to reach out to us! Our team is here to support you on your fitness journey.";
export const COMPLETE_CONTACT_INFO =
  "Please, complete the contact information form. ";
export const COMPLETE_WITH = "Complete with your credit card information";
export const COMPLETE_SUBSCRIPTION = "Complete subscription";
export const DASHBOARD = "Dashboard";
export const DEVELOP_BY = "Developed by:";
export const EDIT_YOUR_GYM_CARD = "Edit your Gym card";
export const EDIT_GYM_CARD = "Edit Gym card";
export const EMPTY_CART = "Your cart is empty.";
export const EMAIL_ALREADY_IN_USE = "Email already in use. Try another one.";
export const ERROR_EMAIL_ALREADY_IN_USE = "auth/email-already-in-use";
export const EXPLORE = "Explore";
export const GYM_HOP_TITLE = "Select your next Gym";
export const GYM_CARD_CREATED = "Gym card created!";
export const GYM_HOP_DESCRIPTION =
  "Start with one, and experience them all! Your membership benefits like never before.";
export const GO_TO_DASHBOARD = "Go to my Dashboard";
export const HERO_TITLE = "The World of Fitness is Yours";
export const HERO_SUBTITLE =
  "Access World-Class Gyms with Full Benefits | 30-Day Membership | Upgrade or Keep Hopping";
export const HERO_SUBTITLE_MOBILE_1 =
  "Access World-Class Gyms with Full Benefits";
export const HERO_SUBTITLE_MOBILE_2 = "30-Day Membership";
export const HERO_SUBTITLE_MOBILE_3 = "Upgrade or Keep Hopping";
export const HERO_BUTTON = "Find your gym today";
export const HOW_IT_WORKS_TITLE_PART_1 = "Hop in.";
export const HOW_IT_WORKS_TITLE_PART_2 = "Become a member in seconds";
export const HOW_IT_WORKS_SUBTITLE =
  "Gyms offer day-to-day on an intuitive and friendly Gym Hop.";
export const HOW_IT_WORKS_STEP_ONE = "Become a JackedRabbit.";
export const HOW_IT_WORKS_STEP_ONE_DESC =
  "Select your first Gym, create your account, and start your journey!";
export const HOW_IT_WORKS_STEP_ONE_DESC_MOBILE = "Select Your First Gym.";
export const HOW_IT_WORKS_STEP_TWO = "Get to Work.";
export const HOW_IT_WORKS_STEP_TWO_DESC =
  "Enjoy the benefits of your 30-day unlimited membership.";
export const HOW_IT_WORKS_STEP_THREE = "Upgrade or Hop";
export const HOW_IT_WORKS_STEP_THREE_DESC =
  "Upgrade to a fulltime Power membership if you found the right fit, or Hop to the next!";
export const HOW_IT_WORKS_STEP_THREE_DESC_MOBILE =
  "Upgrade to a full time membership or choose your next gym!";
export const INVALID_EMAIL = "Invalid email address";
export const IN_YOUR_UPCOMING = "in your upcoming monthly bill";
export const FIND_YOUR_NEW_GYM = "Find your new home or keep hopping";
export const FORGOT_PASS = "Forgot Password?";
export const FORGOT_PASS_TILE = "Forgot Password";
export const JACKEDRABBIT = "JACKEDRABBIT";
export const JACKED_RABBIT_MODE = "JackedRabbit Mode";
export const JACKED_RABBIT_MODE_DESCRIPTION =
  "Select your next gym before your monthly membership for your current gym expires.";
export const JACKEDRABBIT_FOOTER = "JackedRabbit";
export const JR_EMAIL = "support@jackedrabbitgyms.com";
export const JR_PHONE = "(305) 244-8777";
export const JOIN_OUR_COMMUNITY_TEAM = "Join our Community Team";
export const LEARN_MORE = "Learn more";
export const LOADING = "Loading";
export const LOGIN = "Login";
export const LOGIN_APPLE = "Log in with Apple";
export const LOGIN_GOOGLE = "Log in with Google";
export const LOGIN_TO = "Log in to JackedRabbit";
export const LOGOUT = "Logout";
export const MEMBERS_LIST = "Members List";
export const MEMBERSHIP_EXPIRATION = "Membership expiration";
export const MEMBERSHIP_SUBSCRIPTION = "Membership Subscription";
export const MEMBERSHIP_UPGRADED = "Membership Upgraded 💪";
export const MEMBERSHIP = "Membership";
export const MEMBERSHIP_FEE = "Membership fee:";
export const MEMBERSHIP_TYPE = "Membership type";
export const MISSION_AND_VISION = "Mission and Vision";
export const MIME_TYPES = "JPG or PNG format * ";
export const MESSAGE_SENT = "Message sent successfully!";
export const MUST_BE_SAME_AS = "Must be the same as Password";
export const MUST_HAVE_SIX = "Must have 6 or more characters long";
export const NEXT = "Next";
export const NEXT_GYM = "Next Gym:";
export const NEXT_GYM_UPDATED = "Next gym updated! 💪";
export const NEXT_GYM_SELECTED = "Next gym selected ✅";
export const NO_MEMBERS_TO_SHOW = "There are no members to show. ";
export const NO_UPGRADE_MODE_TILE =
  " To become a full-time member at this gym, please cancel your JackedRabbit subscription, and review membership options at the front desk with an associate.";
export const NO_ACTIVE_MEMBERSHIP = "No active memberships to show";
export const EOM = "E.O.M Software";
export const EMAIL = "Email";
export const E_MAIL = "E-mail";
export const OUR_PARTNERS = "Our partners";
export const OTHERS_PAYMENTS = "Other payment methods available for you";
export const OK = "Ok";
export const PARTNER = "Partner";
export const PAY_WITH_APPLE = "Pay whith Apple Pay";
export const PAYMENT_DETAILS = "Payment Details *";
export const PERSONAL_INFORMATION = "Personal Information";
export const PHONE = "Phone";
export const PHONE_NUMBER = "Phone Number";
export const PICTURES_SELECTED = "Select Pictures * (3 maximum)";
export const PROFILE_CARD_CREATED = "Profile Card Created ✅";
export const PLEASE_SELECT_YOUR_NEXT =
  "Select your next gym before your current membership expires or we will pick one for you! ⚠️";
export const PLEASE_COMPLETE_THE_PAYMENT_FLOW =
  "We noticed that the payment flow wasn't complete successfully. Please, complete your membership purchase again.";
export const RIGHT_RESERVED = "JackedRabbit 2024 - © All rights reserved";
export const SAVE = "Save";
export const SELECT_YOUR_NEXT_GYM = "Select Your Next Gym";
export const SELECT_AMENITIES_AVAILABLE = "Select Amenities available *";
export const SELECT = "Select";
export const SELECTION_PENDING = "selection pending...";
export const SEND = "Send link";
export const PARTNER_SIGNUP = "Partner sign up";
export const SIGNUP = "Sign Up";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const START_NOW = "Start now!";
export const SOLD_OUT = "Sold out";
export const PAYMENT_SUCCESS =
  "You have successfully upgraded your membership. You can enjoy your new membership after the current membership expiration date ends.";
export const PROFILE_UPDATED = "Profile updated successfully 🎉";
export const PLEASE_CHECK_YOUR_CARD =
  "Please, review your card information and try again.";
export const SUBMIT = "Submit!";
export const SUMMARY = "Summary";
export const SELECT_YOUR_GYM = "Select your gym for the month";
export const USER = "User";
export const TOTAL = "Total:";
export const TAX = "Tax (7%):";
export const TRY_AGAIN = "Try again";
export const TRANSACTION_FEE = "Transaction fee (3.5%):";
export const WELCOME = "Welcome,";
export const WE_COULDNT_PROCCESS =
  "We couldn't process your information. Please, use a valid email.";
export const WE_COULDNT_PROCCESS_YOUR_REQUEST =
  "We couldn't process your request right now. Please, try again later";
export const WE_COULDNT_PROCCESS_PAYMENT = "We couldn't process your payment ";
export const YOU_WILL_BE_CHARGE = "You will be charge";
export const YOU_WILL_LOSE_YOU =
  "You will lose your account. This operation cannot be undone.";
export const YOUR_ARE_ABOUT_TO_SELECT =
  "You are about to change your gym for the upcoming month ";
export const YOUR_GYM_FOR_THE_UPCOMING =
  "Your gym for the upcoming month has been successfully updated.";
export const YOU_CANT_REPEAT_GYM =
  "You can't repeat the same Gym in a period of 3 months";
