import styled from "styled-components";
import theme from "../../utils/theme";

const { colors, templateColors } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background-color: ${templateColors.primaryBlack};
  width: 350px;
  height: 282px;
  border-radius: 6px;
  margin-right: 20px;
  margin-top: 25px;

  @media (max-width: 1224px) {
    width: 100%;
    margin-right: 0px;
    height: 290px;
  }
`;

export const DescriptionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${templateColors.grey};
  width: 100%;
  padding: 2% 5% 2% 5%;
  height: 280px;
  cursor: pointer;
  border-radius: 6px;
  @media (max-width: 1224px) {
    padding: 2% 7% 2% 7%;
    height: 281px;
  }
`;

export const PresentationBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
`;

export const Title = styled.span`
  color: ${colors.white};
  font-weight: 600;
  font-size: 18px;
  @media (max-width: 1224px) {
    font-size: 14px;
    font-weight: 600;
  }
`;

export const Description = styled.span`
  color: ${templateColors.lightGrey};
  margin-top: 5px;
  font-size: 10px;
  line-height: 15px;
  /* height: 70px; */
  @media (max-width: 1224px) {
    margin-top: 6px;
    line-height: 14px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  margin-right: 20px;

  @media (max-width: 1224px) {
    width: 100%;
    height: 100%;
    margin-right: 8px;
  }
`;

export const GymName = styled.div`
  display: flex;
  background-color: ${colors.black};
  opacity: 0.4;
  align-items: "center";
  justify-content: center;
  width: 100%;
  padding: 10%;
  height: 20%;
  @media (max-width: 1224px) {
    width: 100%;
    height: 100px;
    padding-left: 6%;
    padding-right: 2%;
  }
`;

export const GymNameTitle = styled.span`
  color: ${colors.white};
  font-size: 17px;
  font-weight: 800;
  @media (max-width: 1224px) {
  }
`;

export const AmenitiesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 26px;
  height: 50px;
  @media (max-width: 1224px) {
    margin-bottom: 25px;
    height: 70px;
  }
`;

export const AddressIcon = styled.img`
  width: 11px;
  height: 11px;
  margin-right: 10px;

  @media (max-width: 1224px) {
    width: 13px;
    height: 13px;
    margin-right: 8px;
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
  margin-top: 15px;
  align-items: baseline;
  @media (max-width: 1224px) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

export const Address = styled.a`
  color: ${templateColors.lightYellow};
  font-size: 9px;
  line-height: 12px;
  text-decoration: none;
  @media (max-width: 1224px) {
  }
`;

export const SocialIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Price = styled.span`
  color: ${colors.white};
  font-size: 20px;
  font-weight: 400;
  margin-left: 5px;
  @media (max-width: 1224px) {
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 90%;
  margin-top: 5px;
  margin-bottom: 30px;
  height: 4px;
  @media (max-width: 1224px) {
    margin-top: 13px;
    margin-bottom: 25px;
  }
`;

export const SocialIcon = styled.img`
  background-size: 14px 14px;
  margin-right: 8px;
  @media (max-width: 1224px) {
    background-size: 5px 5px;
    margin-right: 8px;
  }
`;

export const SocialIconRef = styled.a`
  display: flex;
  align-items: center;
`;
