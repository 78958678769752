import { jwtDecode, JwtPayload } from "jwt-decode";
import dayjs from "dayjs";

export const validatePassword = (password: string) => {
  const regex = /^[A-Za-z\d@$!%*?&]{6,}$/;
  return regex.test(password);
};

export const isTokenExpired = (token: string) => {
  if (!token) return true;
  try {
    const decodedToken: JwtPayload = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp && decodedToken.exp < currentTime;
  } catch (error) {
    console.error("Error decoding token:", error);
    return true;
  }
};

export const authTime = (token: string) => {
  try {
    const decodedToken: any = jwtDecode(token);
    return decodedToken.auth_time && decodedToken.auth_time;
  } catch (error) {
    console.error("Error authTime:", error);
    return "";
  }
};

export const addDollarSign = (number: string) => {
  if (!number.startsWith("$")) {
    number = "$ " + number;
  }
  return number;
};

export const removeDollarAndConvertToNumber = (str: string) => {
  // Remove the dollar sign from the beginning of the string
  let cleanString = str.replace(/^\$/, "");
  // Convert the cleaned string to a number
  let number = parseFloat(cleanString);
  return number;
};

export const filterByName = (arr: any, name: string) => {
  return arr?.find((item: any) => item?.name === name);
};

export const serviceId = "service_sm2jc3d";
export const templateId = "template_m1se2bd";
export const publicKey = "iACUVMHVWhvpJsG4x";

export const convertToSerializable = (value: any) => {
  try {
    return JSON.stringify(value);
  } catch (error) {
    console.error("Failed to serialize value:", error);
    return null;
  }
};

export const daysUntilExpiration = (expirationDate?: string) => {
  // Parse the input date
  const expiration = new Date(expirationDate || "");
  const today = new Date();

  // Calculate the difference in time
  const timeDiff = expiration.getTime() - today.getTime();

  // Convert time difference from milliseconds to days
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  // Return the formatted message
  return `${daysDiff} days left`;
};

export const isMembershipCancelled = (scheduledCancelAt?: string) => {
  if (scheduledCancelAt === null) {
    return false;
  }
  // Parse the input date
  const cancelDate = new Date(scheduledCancelAt || "");
  const today = new Date();

  // Compare dates
  if (today >= cancelDate) {
    return true;
  } else {
    return false;
  }
};

export const getOrdinal = (day: number) => {
  if (day > 3 && day < 21) return "th"; // 4th to 20th
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const formatDate = (inputDate: string) => {
  // Parse the input date using dayjs
  const date = dayjs(inputDate);

  // Extract day, month, and year
  const day = date.date();
  const month = date.format("MMMM");
  const year = date.year();

  // Construct the formatted date string with ordinal suffix
  const formattedDate = `${month} ${day}${getOrdinal(day)} ${year}`;

  return formattedDate;
};

export const replaceUrlFormat = (url: string) => {
  return url.replace(/^www\./, "http://").replace(/^http:\/\//, "http://");
};

// Function to sort array based on custom gym order and hide a specific gym
export const sortGymsByCustomOrder = (array: any, gymToHide: string) => {
  const customOrder = [
    "Omega Wellness Club",
    "AIRLAB Fitness",
    "Elev8tion Fitness",
    "Purepower Cycle Midtown Miami",
    "CKO EDGEWATER",
    "UFC GYM Midtown ",
  ];

  // Sort gyms that are in the custom order
  const orderedGyms = customOrder
    .map((gymName) =>
      array?.find(
        (item: { name: string | string[] }) =>
          item?.name.includes(gymName) && item?.name !== gymToHide
      )
    )
    .filter(Boolean);

  // Filter out gyms that are already in the custom order and exclude the gym to hide
  const extraGyms = array?.filter(
    (item: { name: string | string[] }) =>
      !customOrder?.some((gymName) => item?.name.includes(gymName)) &&
      item?.name !== gymToHide
  );

  // Combine ordered gyms with extra gyms at the end
  return [...orderedGyms, ...extraGyms];
};

export const injectScript = () => {
  // Check if the script is already added
  if (
    document.querySelector(
      `script[src="//jackedrabbit.cakeportals.com/local_assets/js/jssdk/lib.js"]`
    )
  ) {
    return; // Script is already loaded, do nothing
  }

  // Load the external library
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.src = "//jackedrabbit.cakeportals.com/local_assets/js/jssdk/lib.js";

  // Add error handling for script load failure
  script.onerror = () => {
    console.error("Failed to load the CKMLib library script.");
  };

  // Load the configuration script after the library is loaded
  script.onload = () => {
    const configScript = document.createElement("script");
    configScript.type = "text/javascript";
    configScript.text = `
      (_ckm = window._ckm || []).push(function cfgev() {
        CKMLib.configureEvents({
          domain: 'https://jackedrabbit.cakeengine.net',
          offer_id: 2,
          event_id: 1
        });
        CKMLib.fireEvent();
      });
      if (typeof CKMLib != 'string') {
        CKMLib = 'loading';
      }
    `;
    document.body.appendChild(configScript);
  };

  document.body.appendChild(script);
};
