import { store } from "../redux/store";
import FormData from "form-data";

import {
  setAllBusiness,
  setBusinessDashboardData,
  setBusinessProfile,
  setIsNextGymSelected,
  setMembershipUpgraded,
  setPartnerGymInfo,
  setUserCanDowngradeToBasic,
  setUserData,
  setUserMembershipStatus,
} from "../slices/appSlice";
import {
  createBusinessProfile,
  getAllBusiness,
  getBusinessDashboardData,
  getBusinessProfile,
  getPartnerGymInfo,
  getStripeConfig,
  getUserMembershipStatus,
  refreshUser,
  subscribeToNextGym,
  updateGallery,
  uploadImage,
} from "./apiEndpoints";
import { daysUntilExpiration } from "../utils/utils";
import { YOU_CANT_REPEAT_GYM } from "../utils/strings";

const dispatch = store.dispatch;

// ------- User  -------

export const getUserCall = async () => {
  try {
    const result = await refreshUser();
    dispatch(setUserData(result?.data.user));
  } catch (error) {
    console.log("getUserCall()", error);
  }
};

export const getUserMembershipStatusCall = async () => {
  try {
    const result = await getUserMembershipStatus();
    dispatch(setUserMembershipStatus(result?.data));
    dispatch(
      setMembershipUpgraded(
        result.data?.settings?.nextProduct?.name === "premium"
      )
    );
    // This is the case when a user has bought a basic membership and has just upgrade for the upcoming month, we want to let the user downgrade to basic only during the month when the upgraded membership has started
    dispatch(
      setUserCanDowngradeToBasic(
        result?.data?.memberships?.active?.product?.name === "premium" &&
          result?.data?.settings?.nextProduct?.name === "premium"
      )
    );
    dispatch(
      setIsNextGymSelected(
        result.data?.settings?.nextGymChoiceMode === "pick" ||
          result.data?.settings?.nextGymChoiceMode === null
      )
    );
  } catch (error) {
    console.log("getUserMembershipStatusCall()", error);
  }
};

// ------- Payments  -------

export const getStripeConfigCall = async () => {
  try {
    const result = await getStripeConfig();
    const { publishableKey } = result?.data;
    return publishableKey;
  } catch (error) {
    console.log("getStripeConfigCall", error);
  }
};

// ------- Bussines (Gym Owners)  -------

export const getPartnerGymInfoCall = async () => {
  try {
    const result = await getPartnerGymInfo();
    const partnerGymInfo = result?.data?.business;
    dispatch(setPartnerGymInfo(partnerGymInfo));
  } catch (error) {
    console.log("getPartnerGymInfoCall", error);
  }
};

export const getBusinessProfileCall = async (businessId: string) => {
  try {
    const result = await getBusinessProfile(businessId);
    const businessData = result?.data?.business;
    dispatch(setBusinessProfile(businessData));
  } catch (error) {
    console.log("getBusinessProfileCall", error);
  }
};

export const getBusinessDashboardDataCall = async (businessId: string) => {
  try {
    const result = await getBusinessDashboardData(businessId);
    dispatch(setBusinessDashboardData(result?.data));
  } catch (error) {
    console.log("getBusinessDashboardDataCall", error);
  }
};

export const getAllBusinessCall = async () => {
  try {
    const result = await getAllBusiness();
    const businessData = result?.data?.business;
    dispatch(setAllBusiness(businessData));
  } catch (error) {
    console.log("getAllBusinessCall", error);
  }
};

export const subscribeToNextGymCall = async (businessProductId: string) => {
  try {
    const result = await subscribeToNextGym(businessProductId);
    const success = result?.status === 200;
    dispatch(setIsNextGymSelected(success));
    await getUserMembershipStatusCall();
    return result?.status;
  } catch (error: any) {
    console.log("subscribeToNextGymCall", error);
    if (error?.response?.data?.errors?.[0] === YOU_CANT_REPEAT_GYM) {
      return 2;
    } else {
      return 1;
    }
  }
};
export const createBusinessProfileCall = async (data: any) => {
  try {
    const result = await createBusinessProfile(data);
    const profile = result?.data;
    dispatch(setBusinessProfile(profile));
    if (result?.status === 200 || result?.status === 201) {
      await getPartnerGymInfoCall();
      return "success";
    }
  } catch (error) {
    alert(`Create partner profile status:, ${error}`);
    return "error";
  }
};

export const getSignedGalleryUrlCall = async (
  imageData1: any,
  imageData2: any,
  imageData3: any
) => {
  try {
    const fileData1 = imageData1[0]?.file as any;
    const formData = new FormData();
    formData.append("image", fileData1);

    const fileData2 = imageData2[0]?.file as any;
    const formData2 = new FormData();
    formData2.append("image", fileData2);

    const fileData3 = imageData3[0]?.file as any;
    const formData3 = new FormData();
    formData3.append("image", fileData3);

    const uploadImageResult: any = await Promise.all([
      uploadImage(formData),
      uploadImage(formData2),
      uploadImage(formData3),
    ]);

    if (
      (uploadImageResult?.[0].status === 200 ||
        uploadImageResult?.[0].status === 201) &&
      (uploadImageResult?.[1].status === 200 ||
        uploadImageResult?.[1].status === 201) &&
      (uploadImageResult?.[2].status === 200 ||
        uploadImageResult?.[2].status === 201)
    ) {
      const updateGalleryResult = await updateGallery([
        {
          resourceUri: uploadImageResult?.[0]?.data?.resourceUri,
          contentType: imageData1?.[0]?.file?.type, // mimeTypes
        },
        {
          resourceUri: uploadImageResult?.[1]?.data?.resourceUri,
          contentType: imageData2?.[0]?.file?.type, // mimeTypes
        },
        {
          resourceUri: uploadImageResult?.[2]?.data?.resourceUri,
          contentType: imageData3?.[0]?.file?.type, // mimeTypes
        },
      ]);

      if (
        updateGalleryResult?.status === 200 ||
        updateGalleryResult?.status === 201
      )
        return "success";
    }
  } catch (error) {
    alert(`Gallery update status:, ${error}`);
    return "error";
  }
};
